import React from "react";
import { View, Image, Text, styled } from "dripsy";
import { StyleSheet, TouchableOpacity, FlatList, Platform } from "react-native";
import { observer } from "mobx-react";
import Analytics from "../Analytics";
import { useStores } from "../stores";
import HomeBar from "../components/HomeBar";
import Tabs from "../components/Tabs";
import CachedImage from "../components/CachedImage";
import { FontAwesome, AntDesign } from "@expo/vector-icons";
import Programm from "../components/Programm";

const Img = styled(CachedImage)({});
function Home(props) {
  const { store } = useStores();
  const [tab, setTab] = React.useState(0);

  React.useEffect(() => {
    store.getRadioLink();
    store.getNews();
    store.getProgramm();
    Analytics.track(Analytics.events.HOME)
  }, []);

  const renderNews = ({ item }) => {
    return (
      <TouchableOpacity onPress={()=>props.navigation.navigate('news',item)} style={{marginTop:8}}>
        <View sx={{ flexDirection: "row", alignItems: "center" }}>
          {item.image?.length > 3 ? (
            <Img
              sx={{
                height: 90,
                width: 110,
                borderRadius: 10,
                marginRight: 3,
                
              }}
              source={{ uri: item.image }}
            />
          ):(
              <View sx={{
                height: 90,
                width: 110,
                borderRadius: 10,
                marginRight: 3,
                backgroundColor: "header",
                justifyContent:"flex-end",
                alignItems:"center"
              }}>
                  <FontAwesome name="newspaper-o" size={40} color="#99213a" style={{marginBottom: 8}} />
                  
                  <View sx={{
                       backgroundColor:"blueText",
                       borderBottomRightRadius:10,
                       borderBottomLeftRadius:10,
                  }}>
                      <Text  sx={{
                     
                      color:"white",
                      width:110,
                      textAlign:'center',
                      padding: 2,
                      fontWeight:"bold",
                  }}>{item.date}</Text>
                  </View>
              </View>
          )}
          <View style={{ margin: 2, flexDirection: "column", flex: 1 }}>
            <Text
              sx={{
                fontSize: [16, 22],
                fontWeight: "bold",
                color: "#5d6e9a",
                flexWrap: "wrap",
                lineHeight: [20, 24],
              }}
            >
              {item.title}
            </Text>
            <Text
              sx={{
                color: "#a6abb7",
                fontSize: [16],
                marginTop: 1,
              }}
            >
              {item.date}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <View
      sx={{
        backgroundColor: "#f9fcff",
        flex: 1,
      }}
    >
      <HomeBar />
      <Tabs
        active={tab}
        onClick={(id) => setTab(id)}
        tabs={[
          { title: "News", id: 0 },
          { title: "Programm", id: 1 },
        ]}
      />

      {tab === 0 ? (
        <FlatList
          onRefresh={() => store.getNews()}
          refreshing={store.newsRefresh}
          style={{ marginHorizontal: 20 }}
          data={store.news}
          keyExtractor={(item) => item.id}
          renderItem={renderNews}
        />
      ):(
          <Programm/>
      )
    
    }


    </View>
  );
}

export default observer(Home);
