import React from "react";
import { View, Image, Text, styled } from "dripsy";
import { StyleSheet, TouchableOpacity, FlatList, Platform } from "react-native";
import { observer } from "mobx-react";
import { useStores } from "../stores";
import Constants from "expo-constants";
import logo from "../../assets/plogo.png";
import album from "../../assets/cmaalbum.png";
import { FontAwesome } from "@expo/vector-icons";

function HomeBar(props) {
  const { store } = useStores();

  return (
    <View
      sx={{
        paddingTop: [Constants.statusBarHeight, 15],
        px: [25, 40, 40],
        backgroundColor: "header",
        shadowColor: "gray",
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.2,
        shadowRadius: 9,
        elevation: 1,
      }}
    >
      <View
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <View>
          <Text
            sx={{
              fontSize: 22,
              fontWeight: "bold",
              color: "blueText",
            }}
          >
            Coptic Media Austria
          </Text>
          <Text
            style={{
              fontSize: 20,

              color: "#223F72",
            }}
          >
            Christus mein Anker
          </Text>
        </View>
        <Image
          source={logo}
          style={{ resizeMode: "contain" }}
          sx={{
            height: 70,
            width: 70,
          }}
        />
      </View>
      <View
        sx={{
          marginTop: 4,
          backgroundColor: "white",
          height: 90,
          borderRadius: 8,
          marginBottom: 3,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 2,
        }}
      >
        <View sx={{ flexDirection: "row", alignItems: "center" }}>
          <Image
            source={album}
            style={{ resizeMode: "contain" }}
            sx={{
              height: 60,
              width: 60,
              borderRadius: 8,
              marginRight: 20,

              marginLeft: 2,
            }}
          />
          <View>
            <Text
              sx={{
                fontSize: 22,
                fontWeight: "bold",
                color: "second",
                marginLeft: 1,
              }}
            >
              CMA Radio
            </Text>
            <Text
              sx={{
                fontSize: 18,
                color: "mgrey",
                marginLeft: 1,
              }}
            >
              Jetzt live hören
            </Text>
          </View>
        </View>

        <TouchableOpacity
          onPress={() => {
            store.setRadio();
          }}
        >
          <FontAwesome
            name={!store.radio ? "play" : "pause"}
            size={28}
            color="#5d6e9a"
            style={{ marginRight: 25 }}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
}

export default observer(HomeBar);
