import React from "react";
import {
  ScrollView,
  Platform,
  TouchableOpacity,
  FlatList,
  Dimensions,
} from "react-native";
import { View, Image, Text, styled } from "dripsy";
import Analytics from "../Analytics";
import { observer } from "mobx-react";
import { useStores } from "../stores";
import { toJS } from 'mobx';  
import soon from "../../assets/soon.png";
import { FontAwesome } from "@expo/vector-icons";
import CachedImage from "../components/CachedImage";
import LibBar from "../components/LibBar";
const Img = styled(CachedImage)({});


const screenWidth = Dimensions.get("window").width;
const webTitleSize = screenWidth / 6;
const tileSize = screenWidth / 2;


function Lib(props) {
 
  const { store } = useStores();
  React.useEffect(() => {
    store.getAlbums();
    Analytics.track(Analytics.events.BIBLIOTHEK)
  }, []);
  return (
    <View
      sx={{
        backgroundColor: "#f9fcff",
        flex: 1,
      }}
    >
      <LibBar back={false} title="Bibliothek" />

      <FlatList
         onRefresh={() => store.getAlbums()}
         refreshing={store.albumsRefresh}
        columnWrapperStyle={{
          flexWrap: "wrap",
          flex: 1,
          justifyContent: "flex-start",
        }}
        contentContainerStyle={{
          marginTop: 10,
        }}
        data={toJS(store.albums)}
        horizontal={false}
        renderItem={({ item, index }) => (
          <TouchableOpacity onPress={()=>{!item.soon &&
            props.navigation.navigate("album",item)
          }} style={{ padding: 15,  }} >
            <Img
              source={{ uri: item.image }}
              style={{ resizeMode: "contain", borderRadius:0 }}
              sx={{
                height: [tileSize - 30, webTitleSize - 30],
                width: [tileSize - 30, webTitleSize - 30],
                
              }}
            />
            {item.soon && <Image
              source={soon}
              style={{ position:"absolute",top: 15,
              bottom: 0,
              left: 15,
              right: 0, resizeMode: "contain",borderRadius:0 }}
              sx={{
                height: [tileSize - 30, webTitleSize - 30],
                width: [tileSize - 30, webTitleSize - 30],
              }}
            />}
            <View
              sx={{
                width: [tileSize - 30, webTitleSize - 30],
                padding: 1,
                paddingX: 1,
                paddingTop:2
              }}
            >
              <Text
                numberOfLines={2}
                style={{
                  flexWrap: "wrap",
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#223F72",
                }}
              >
                {item.title}
              </Text>
            </View>
          </TouchableOpacity>
        )}
        numColumns={10}
        keyExtractor={(item, index) => index}
      />
    </View>
  );
}

export default observer(Lib);