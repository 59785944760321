import React from "react";
import { View, Image, Text } from "dripsy";
import logo from "../../assets/plogo.png";
import Constants from "expo-constants";
import { Ionicons } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native";
function LibBar(props) {
  return (
    <View
      style={props.shadow && {
        shadowColor: "gray",
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.2,
        shadowRadius: 9,
        elevation: 1,
      }}
      sx={{
        paddingTop: [Constants.statusBarHeight, 15],
        px: [25, 40, 40],
        paddingBottom: 2,
        backgroundColor: "header",
      }}
    >
      <View
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#f2f3ff",
        }}
      >
        <View
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {props.back && (
            <TouchableOpacity onPress={props.back && props.goBack}>
              <Ionicons
                name="arrow-back"
                size={34}
                color="#223F72"
                style={{ marginRight: 15, paddingBottom: 2, height: 34 }}
              />
            </TouchableOpacity>
          )}
          {props.title && (
            <Text
              style={{
                fontSize: 26,
                lineHeight: 34,
                fontWeight: "bold",
                color: "#223F72",
              }}
            >
              {props.title}
            </Text>
          )}
        </View>
        <Image
          source={logo}
          style={{ resizeMode: "contain" }}
          sx={{
            height: 70,
            width: 70,
          }}
        />
      </View>
    </View>
  );
}

export default LibBar;
