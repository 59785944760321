import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { StatusBar } from 'expo-status-bar';
import BottomBar from './components/BottomBar';
import Home from './screens/Home';
import Lib from './screens/Lib';
import More from './screens/More';
import News from './screens/News';
import Album from './screens/Album';

const Tabs = createBottomTabNavigator();
const HomeStack = createStackNavigator();
const LibStack = createStackNavigator();

function LibStackScreen() {
  return (
    <LibStack.Navigator headerMode="none"  >
      <LibStack.Screen
        name="library"
        component={Lib}
      />
      <LibStack.Screen
        name="album"
        component={Album}
      />
    </LibStack.Navigator>
  );
}

function HomeStackScreen() {
    return (
      <HomeStack.Navigator headerMode="none"  >
        <HomeStack.Screen
          name="home"
          component={Home}
        />
        <HomeStack.Screen
          name="news"
          component={News}
        />
      </HomeStack.Navigator>
    );
  }
  

export default function Router(){
    return (
        <NavigationContainer linking>
            <StatusBar style="dark" />
            <Tabs.Navigator screenOptions={{ tabBarVisible: false }}
             tabBar={(props) => <BottomBar {...props} />}
            >
                <Tabs.Screen name="hometab" component={HomeStackScreen} />
                <Tabs.Screen name="librarytab" component={LibStackScreen} />
                <Tabs.Screen name="more" component={More} />
            </Tabs.Navigator>
        </NavigationContainer>
    )
}