import React from "react";
import { View, Image, Text, styled } from "dripsy";
import {
  StyleSheet,
  TouchableOpacity,
  FlatList,
  Platform,
  ScrollView,
} from "react-native";
import { observer } from "mobx-react";
import { useStores } from "../stores";
import LibBar from "../components/LibBar";
import Tabs from "../components/Tabs";
import CachedImage from "../components/CachedImage";
import { FontAwesome, AntDesign } from "@expo/vector-icons";
import Programm from "../components/Programm";
import { toJS } from "mobx";

const Img = styled(CachedImage)({});

function News({ route, navigation }) {
  const { store } = useStores();
  const [tab, setTab] = React.useState(0);
  const item = toJS(route.params);
  console.log(toJS(route.params));

  return (
    <View
      sx={{
        backgroundColor: "#f9fcff",
        flex: 1,
      }}
    >
      <LibBar
        goBack={() => {
          navigation.navigate("home");
        }}
        back={true}
        title={"News"}
      />
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{
          alignItems: "center",
        }}
      >
        {item.image?.length > 3 && (
          <Img
            sx={{
              height: 90 * 3,
              width: 160 * 3,
              borderRadius: 10,
              marginRight: 3,
              resizeMode: "contain",
              marginTop: 20,
              backgroundColor:"blueText"
            }}
            source={{ uri: item.image }}
          />
        )}
        <View sx={{ width: "100%", paddingX: 5, marginTop: 6 }}>
          <Text
            sx={{
              fontSize: [22, 22],
              fontWeight: "bold",
              color: "blueText",
              flexWrap: "wrap",
              lineHeight: [24, 24],
            }}
          >
            {item.title}
          </Text>
          <Text
            sx={{
              color: "#a6abb7",
              fontSize: [18],
              marginY: 2,
            }}
          >
            {item.date}
          </Text>
          <Text
            sx={{
              color: "#5d6e9a",
              fontSize: [18],
              marginY: 2,
            }}
          >
            {item.text}
          </Text>
        </View>
      </ScrollView>
    </View>
  );
}

export default observer(News);
