
import firebase from "firebase/app";
import "firebase/firestore";
import React from 'react';
import Analytics from "./src/Analytics";
import { StatusBar } from 'expo-status-bar';
import { Asset } from "expo-asset";
import AppLoading from "expo-app-loading";

import { DripsyProvider } from "dripsy";


import Router from "./src/Router";
import { useStores } from "./src/stores";

const firebaseConfig = {
  apiKey: "AIzaSyAH6OTmf07ECJcsONDInZUWbGKayAdmFsI",
  authDomain: "copticmediaaustria.firebaseapp.com",
  projectId: "copticmediaaustria",
  storageBucket: "copticmediaaustria.appspot.com",
  messagingSenderId: "251945039648",
  appId: "1:251945039648:web:cfe5ebd5d11747e8a70945",
  measurementId: "G-3REHM4XXPQ",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const theme = {
  colors: {
    text: "#000",
    blueText:"#223F72",
    background: "#fff",
    primary: "#99213a",
    second: "#5d6e9a",
    mgrey: "#a6abb7",
    back: "#e5e8f6",
    header:"#f2f3ff",
    txt:"#40507B"

  },
  space: [0, 2, 5, 8, 10, 16, 20, 25, 32 ,40, 50, 64, 70, 80, 128],
  fontSizes: [8, 10, 12, 14 , 16, 18, 20, 24, 32],
};

export default function App() {
  const { store } = useStores();
  const [isReady, setIsReady] = React.useState(false);

  React.useEffect(() => {
    store.login();
    Analytics.track(Analytics.events.APP_OPEN)
    Analytics.track(Analytics.events.V)
  }, []);

  const _cacheResourcesAsync = async () => {
    const images = [
      require('./assets/plogo.png'),
      require('./assets/cmaalbum.png'),
      require('./assets/full.png'),
      require('./assets/soon.png'),
      require('./assets/radio.png'),
      require('./assets/load.png'),
  ];

    const cacheImages = images.map(image => {
      return Asset.fromModule(image).downloadAsync();
    }); 
    return Promise.all(cacheImages);
  }

  if (!isReady) {
    return (
      <AppLoading
        startAsync={_cacheResourcesAsync}
        onFinish={() => setIsReady(true)}
        onError={console.warn}
      />
    );
  } else {
    return (
      <DripsyProvider theme={theme}>
        <StatusBar />
        <Router />
      </DripsyProvider>
    );
  }
}

