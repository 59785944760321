import * as Amplitude from "expo-analytics-amplitude";
import { Platform } from "react-native";

let isInitialized = false;
const apiKey = "7f7b3fe526b8a2b655c01ffd146a2d6e";

const ok = Platform.OS !== "web";

export const events = {
  HOME: "HOME",
  BIBLIOTHEK: "BIBLIOTHEK",
  MORE: "MORE",
  PLAY_RADIO: "PLAY_RADIO",
  PLAY_MUSIK: "PLAY_MUSIK",
  APP_OPEN: "APP_OPEN",
  V: "V2",
};

export function initialize() {
  if (isInitialized || !apiKey) {
    return;
  }

  Amplitude.initializeAsync(apiKey);
  isInitialized = true;
}

export function track(event, options) {
  if (ok) {
    initialize();

    if (options) {
      Amplitude.logEventWithPropertiesAsync(event, options);
    } else {
      Amplitude.logEventAsync(event);
    }
  }
}

export function setUserId(uid) {
  if (ok) {
    initialize();
    Amplitude.setUserIdAsync(uid);
  }
}

export default {
  events,
  initialize,
  track,
  setUserId,
};
