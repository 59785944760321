import React from "react";
import { StyleSheet, TouchableOpacity, Platform } from "react-native";
import { View, Image, Text } from "dripsy";
import Constants from "expo-constants";
import logo from "../../assets/full.png";
import * as Linking from "expo-linking";
import Analytics from "../Analytics";
import * as Device from "expo-device";
import { Fontisto, Feather, SimpleLineIcons } from "@expo/vector-icons";
import * as MailComposer from "expo-mail-composer";

const web = Platform.OS === "web";
export default function More() {
  React.useEffect(() => {
    Analytics.track(Analytics.events.MORE);
  }, []);
  const [tabs, setTab] = React.useState(true);

  const sendEmail = async () => {
    await MailComposer.composeAsync({
      recipients: ["kontakt@copticmedia.at"],
      subject: "",
      body:
        " \n\n\n\n" +
        Device.brand +
        "\n" +
        Device.modelName +
        "\n" +
        Device.osName +
        "\n" +
        Device.osVersion +
        "\n",
    });
  };

  return (
    <View
      sx={{
        backgroundColor: "#f9fcff",
        flex: 1,
      }}
    >
      <View
        sx={{
          paddingTop: [Constants.statusBarHeight, 0],
          backgroundColor: "#f2f3ff",
          shadowColor: "gray",
          shadowOffset: { width: 1, height: 1 },
          shadowOpacity: 0.2,
          shadowRadius: 9,
          elevation: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          source={logo}
          style={{ resizeMode: "contain" }}
          sx={{
            height: 120,
            width: 200,
            marginBottom: 2,
          }}
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
          marginTop: 30,
        }}
      >
        <TouchableOpacity
          style={styles.element}
          onPress={() => {
            Linking.openURL(
              "https://www.youtube.com/channel/UCplzHMjFHQSpcENToQ356HQ"
            );
          }}
        >
          <SimpleLineIcons name="social-youtube" size={28} color={"#5d6e9a"} />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.element}
          onPress={() => {
            Linking.openURL("https://www.facebook.com/copticmediaaustria");
          }}
        >
          <Feather name="facebook" size={28} color={"#5d6e9a"} />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.element}
          onPress={() => {
            Linking.openURL("https://www.instagram.com/copticmediaaustria/");
          }}
        >
          <Feather name="instagram" size={28} color={"#5d6e9a"} />
        </TouchableOpacity>
      </View>

      <TouchableOpacity
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginHorizontal: 20,
          marginTop: 20,
          padding: 20,
          borderWidth: 1,
          borderColor: "#e7e8f5",
          borderRadius: 10,
        }}
        onPress={() => {
          sendEmail();
        }}
      >
        <View>
          <Text
            sx={{
              fontSize: 20,
              color: "#5d6e9a",
              fontWeight: "bold",
            }}
          >
            Kontakt
          </Text>
          <Text sx={{ color: "mgrey" }}>
            Ideen, Fragen, Wünsche, Anregungen
          </Text>
        </View>
        <Fontisto name="email" size={28} color={"#5d6e9a"} />
      </TouchableOpacity>

      {web && (
        <>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginHorizontal: 20,
              marginTop: 20,
              padding: 20,
              borderWidth: 1,
              borderColor: "#e7e8f5",
              borderRadius: 10,
            }}
            onPress={() => {
              Linking.openURL("https://apps.apple.com/at/app/coptic-media-austria/id1571743401");
            }}
          >
            <View>
              <Text
                sx={{
                  fontSize: 20,
                  color: "#5d6e9a",
                  fontWeight: "bold",
                }}
              >
                iOS App
              </Text>
              
            </View>
            <Fontisto name="apple" size={28} color={"#5d6e9a"} />
          </TouchableOpacity>

          <TouchableOpacity
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginHorizontal: 20,
              marginTop: 20,
              padding: 20,
              borderWidth: 1,
              borderColor: "#e7e8f5",
              borderRadius: 10,
            }}
            onPress={() => {
              Linking.openURL("https://play.google.com/store/apps/details?id=com.copticmedia.cma");
            
            }}
          >
            <View>
              <Text
                sx={{
                  fontSize: 20,
                  color: "#5d6e9a",
                  fontWeight: "bold",
                }}
              >
                Android App
              </Text>
             
            </View>
            <Fontisto name="android" size={28} color={"#5d6e9a"} />
          </TouchableOpacity>
        </>
      )}


    </View>
  );
}

const styles = StyleSheet.create({
  element: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#e7e8f5",
    padding: 11,
    borderRadius: 100,
  },
});
