import React from "react";
import { View, Image, Text, styled, useSx } from "dripsy";
import {
  StyleSheet,
  TouchableOpacity,
  FlatList,
  Platform,
  ScrollView,
} from "react-native";
import { observer } from "mobx-react";
import { useStores } from "../stores";
import { AntDesign } from "@expo/vector-icons";
import { toJS } from "mobx";

function DayProgramm(props) {
  const [open, setOpen] = React.useState(props.first ?? false);
  const sx = useSx();
  return (
    <TouchableOpacity
      style={sx({
        borderLeftWidth: 3,
        borderColor: "blueText",
        paddingRight: 5,
        backgroundColor: "header",
        marginHorizontal: 20,
        marginTop: 5,
        paddingBottom: open ? 5 : 0
      })}
      onPress={() => {
        setOpen(!open);
      }}
    >
      <View
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text sx={{ color: "blueText", fontWeight: "bold", padding: 5 }}>
          {props.item[0].date}
        </Text>
        <AntDesign
          name={open ? "caretup" : "caretdown"}
          size={20}
          color="#223F72"
        />
      </View>
      {open &&
        props.item.map((item, index) => (
          <View
            key={index}
            sx={{
              flexDirection: "row",
              alignItems: "center",
              paddingRight: 5,
              padding: 2,
              backgroundColor: "header",
              marginHorizontal: 20,
             
              paddingLeft: 20,
            }}
          >
            <Text sx={{ color: "blueText" }}>{item.time} Uhr</Text>
            <Text sx={{ color: "blueText", paddingLeft: 5, fontWeight: "500" }}>
              {item.title}
            </Text>
          </View>
        ))}
    </TouchableOpacity>
  );
}

function Programm(props) {
  const { store } = useStores();

  
  const renderProgramm = ({ item,index }) => <DayProgramm first={index==0?true:false} item={item} />;
  return (
    <FlatList
      onRefresh={() => store.getProgramm()}
      refreshing={store.programmRefresh}
      data={store.programm}
      keyExtractor={(item, index) => item[0].id}
      renderItem={renderProgramm}
    />
  );
}

export default observer(Programm);
