import React from "react";
import { TouchableOpacity, Platform } from "react-native";
import { Entypo, Ionicons } from "@expo/vector-icons";
import { observer } from "mobx-react";
import { useStores } from "../stores";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import CachedImage from "../components/CachedImage";
import radioLogo from "../../assets/radio.png";
import { toJS } from "mobx";
import { FontAwesome } from "@expo/vector-icons";

import { View, Image, Text, styled } from "dripsy";

const Touch = styled(TouchableOpacity)({
  flex: 1,
  height: 50,
  justifyContent: "center",
  alignItems: "center",
  margin: 7,
});

function BottomBar(props) {
  const insets = useSafeAreaInsets();
  const { store } = useStores();

  const bheight = Platform.OS === "ios" && insets.bottom != 0 ? 84 : 60;

  return (
    <View
      sx={{
        backgroundColor: "back",
        height: bheight+55,
        justifyContent: "flex-end",
      }}
    >
      <View sx={{ flexDirection: "row", backgroundColor: "header",alignItems:"center", justifyContent:'space-between'}}>
        {store.playingPlaylist != null && store.radioMode == false && (
           <View sx={{flexDirection:"row"}}>
            <CachedImage
              source={{ uri: store.playingAlbum.image }}
              style={{
                height: 55,
                width: 55,
              }}
            />
            <View sx={{ justifyContent: "center", marginLeft: 5 }}>
              <Text
                sx={{
                  fontSize: 14,
                  color: "blueText",
                  fontWeight: "bold",
                  marginBottom: 2,
                }}
              >
                {store.playingPlaylist[store.playingIndexInPlaylist].title}
              </Text>
              <Text
                sx={{
                  fontSize: 14,
                  color: "blueText",
                }}
              >
                {store.playingPlaylist[store.playingIndexInPlaylist].author}
              </Text>
            </View>
          </View>
        )}
        {(store.radioMode || !store.playingPlaylist) && (
          <View sx={{flexDirection:"row"}}>
            <Image
              source={radioLogo}
              style={{ resizeMode: "contain" }}
              sx={{
                height: 55,
                width: 55,
              }}
            />
            <View sx={{ justifyContent: "center", marginLeft: 5 }}>
              <Text
                sx={{
                  fontSize: 14,
                  color: "blueText",
                  fontWeight: "bold",
                  marginBottom: 2,
                }}
              >
                CMA Radio
              </Text>
              <Text
                sx={{
                  fontSize: 14,
                  color: "blueText",
                }}
              >
                Live
              </Text>
            </View>
          </View>
        )}
      <TouchableOpacity
          onPress={() => {
           if(store.radioMode){
            store.setRadio()
           }else{
            store.pausePlay(store.playingSong);
           }
            
          }}
        >
          <FontAwesome
            name={!store.status.isPlaying ? "play" : "pause"}
            size={28}
            color="#5d6e9a"
            style={{ marginRight: 25 }}
          />
        </TouchableOpacity>

      </View>

      <View
        sx={{
          backgroundColor: "second",
          height: bheight,
          padding: 3,
          paddingBottom: Platform.OS === "ios" && insets.bottom != 0 ? 8 : 4,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Touch
          onPress={() => {
            props.navigation.navigate("hometab");
          }}
        >
          <Entypo
            name="home"
            size={28}
            color={props.state.index == 0 ? "white" : "#9AAAD3"}
          />
        </Touch>
        <Touch
          onPress={() => {
            props.navigation.navigate("librarytab");
          }}
        >
          <Ionicons
            name="library-sharp"
            size={28}
            color={props.state.index == 1 ? "white" : "#9AAAD3"}
          />
        </Touch>
        <Touch
          onPress={() => {
            props.navigation.navigate("more");
          }}
        >
          <Entypo
            name="menu"
            size={28}
            color={props.state.index == 2 ? "white" : "#9AAAD3"}
          />
        </Touch>
      </View>
    </View>
  );
}

export default observer(BottomBar);
