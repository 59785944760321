import React from "react";
import { View,  Text, styled, useSx } from "dripsy";
import {
  StyleSheet,
  TouchableOpacity,
  FlatList,
  Platform,
  Pressable, Image
} from "react-native";
import { observer } from "mobx-react";
import { useStores } from "../stores";
import LibBar from "../components/LibBar";
import Tabs from "../components/Tabs";
import CachedImage from "../components/CachedImage";
import { AntDesign, Feather, Entypo } from "@expo/vector-icons";
import Programm from "../components/Programm";
import { toJS } from "mobx";
import Modal from "../components/Modal";
import * as Linking from "expo-linking";

function Album(props) {
  const { store } = useStores();
  const { route, navigation } = props;
  const album = toJS(route.params);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalText, setModalText] = React.useState("");
  React.useEffect(() => {
    store.getPlayList(album);
  }, [album.id]);

  const sx = useSx();
  const playingLink = store.playingSong;
  const playerStatus = store.playerStatus;

  return (
    <View
      sx={{
        backgroundColor: "header",
        flex: 1,
      }}
    >

      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalVisible(!modalVisible);
        }}
      >
        <View
          sx={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(1,1,1,0.7)",
          }}
        >
          <View
            sx={{
              backgroundColor: "white",
              borderRadius: 20,
              padding: 5,
              paddingLeft: 6,
              shadowColor: "#000",
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.25,
              shadowRadius: 4,
              elevation: 5,
              marginX: 5,
            }}
          >
            <TouchableOpacity
              style={{ alignSelf: "flex-end" }}
              onPress={() => setModalVisible(!modalVisible)}
            >
              <AntDesign name="close" size={36} color="#5d6e9a" />
            </TouchableOpacity>
            <Text
              sx={{
                fontSize: 22,
                fontWeight: "bold",
                color: "blueText",
                marginY: 3,
              }}
            >
              {modalTitle}
            </Text>
            <Text
              sx={{
                fontSize: 16,

                color: "blueText",
                marginY: 3,
              }}
            >
              {modalText}
            </Text>
          </View>
        </View>
      </Modal>

      <LibBar
        goBack={() => {
          navigation.navigate("library");
        }}
        back={true}
      />
      <FlatList
        ListHeaderComponent={() => (
          <View
            sx={{
              backgroundColor: "header",
              alignItems: "center",
              paddingBottom: 5,
              shadowColor: "gray",
             paddingTop:5
            }}
          >
            <View style={{elevation:10, overflow:"visible"}}>
            <CachedImage
                resizeMode="cover"
              style={[sx({
                height: 192,
                width: 192,
                marginRight: 3,

                
              
              }),{
                shadowColor: "#000",
                shadowOffset: {
                    width: 0,
                    height: 8,
                },
                shadowOpacity: 0.46,
                shadowRadius: 11.14,
                resizeMode:"cover",
            
                overflow:"visible"
              }]}
              source={{ uri: album.image }}
            /></View>
            <View
              sx={{
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                paddingX: 20,
                justifyContent: "space-between",
                
              }}
            >
              <Text
                sx={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "blueText",
                  marginTop: 5,
                }}
              >
                {album.title}
              </Text>
              <TouchableOpacity
                onPress={() => {
                  setModalTitle(album.title);
                  setModalText(album.info);
                  setModalVisible(!modalVisible);
                }}
              >
                <Feather
                  name="info"
                  size={36}
                  color="#223F72"
                  style={{ paddingTop: 20 }}
                />
              </TouchableOpacity>
            </View>
          </View>
        )}
        style={{ flex: 1, backgroundColor: "#f9fcff" }}
        data={toJS(store.playlist)}
        keyExtractor={(item) => item.id + ""}
        renderItem={(item, index) => (
          <View
            sx={{
              paddingX: 6,
              paddingY: 5,
              borderBottomWidth: 1,
              borderBottomColor: "back",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor:"#f9fcff"
            }}
          >
            <View sx={{ flex: 1 }}>
              <Text
                numberOfLines={2}
                sx={{ fontSize: 18, color: "txt", paddingRight: 2 }}
              >
                {item.item.title}
              </Text>
              <Text sx={{ fontSize: 14, color: "second" }}>
                {item.item.author}
              </Text>
            </View>
            <View
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {item.item.youtube?.length > 3 && (
                <TouchableOpacity
                  onPress={() => Linking.openURL(item.item.youtube)}
                >
                  <Feather
                    name="youtube"
                    size={36}
                    color="#5d6e9a"
                    style={{ marginRight: 12 }}
                  />
                </TouchableOpacity>
              )}
              {item.item.info?.length > 1 && (
                <TouchableOpacity
                  onPress={() => {
                    setModalTitle(item.item.title);
                    setModalText(item.item.info);
                    setModalVisible(!modalVisible);
                  }}
                >
                  <Feather
                    name="info"
                    size={36}
                    color="#5d6e9a"
                    style={{ marginRight: 12 }}
                  />
                </TouchableOpacity>
              )}
              <TouchableOpacity
                onPress={() => {
                  store.pausePlay(item.item.link);
                  store.setPlayingPlaylist(store.playlist,item.index,album);
                }}
              >
                <Feather
                  name={
                    playingLink == item.item.link &&
                    playerStatus == "playing"
                      ? "pause-circle"
                      : "play-circle"
                  }
                  size={36}
                  color="#5d6e9a"
                  style={{}}
                />
              </TouchableOpacity>
            </View>
            
           
          </View>
        )}
      />

    </View>
  );
}

export default observer(Album);
