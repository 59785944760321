import React, { useEffect, useState, useRef } from 'react'
import * as Crypto from 'expo-crypto';
import { Image } from 'react-native'
import load from "../../assets/load.png";
import * as FileSystem from 'expo-file-system'




const IMAGE_CACHE_FOLDER = `${FileSystem.cacheDirectory}images/`




const CachedImage =  props => {
  const { source: { uri } } = props
  const cacheKey =   uri;

  const filesystemURI = `${IMAGE_CACHE_FOLDER}${cacheKey}`

  const [imgURI, setImgURI] = useState(filesystemURI)

  const componentIsMounted = useRef(true)

  useEffect(() => {
    loadImage({ fileURI: filesystemURI })

    return () => {
      componentIsMounted.current = false
    }
  }, [props.source.uri])// eslint-disable-line react-hooks/exhaustive-deps





  const loadImage = async ({ fileURI }) => {
    try {
      // Use the cached image if it exists
      const metadata = await FileSystem.getInfoAsync(fileURI)
      if (!metadata.exists) {
        if (componentIsMounted.current) {
          setImgURI(null)
        }
        // download to cache
        if (componentIsMounted.current) {
          await FileSystem.downloadAsync(
            uri,
            fileURI
          )
        }
        if (componentIsMounted.current) {
          setImgURI(fileURI)
        }
      }
    } catch (err) {
      // console.log({ err })
      if (componentIsMounted.current) {
        setImgURI(uri)
      }
    }
  }

  return (
    <Image
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      source={props.source}
   
      defaultSource={load}
    />
  )
};



export default (CachedImage)