import { makeAutoObservable, runInAction, autorun } from "mobx";
import { Audio } from "expo-av";
import Analytics from "../Analytics";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { toJS } from "mobx";

class Store {
  radio = false;
  signedIn = false;

  news = [];
  newsRefresh = false;

  programm = [];
  programmRefresh = false;

  albums = [];
  albumsRefresh = false;

  // MUSIC
  error = false;

  status = {
    isPlaying: false
  };
  playlist = [];
  soundPlayer = null;

  playing = false;
  playingSong = "";
  playingAlbum = null;
  playingPlaylist = null;
  playingIndexInPlaylist = 0;
  playerStatus = "nosound";
  radioMode = true;
  didJustFinish = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  updateStatus = (status) => {
    // console.log(status)
    runInAction(() => {
      this.status = status;
    });
    if (status.isPlaying && this.playerStatus != "playing") {
      runInAction(() => {
        this.playerStatus = "playing";
      });
      console.log("update playing");
    } else if (!status.isPlaying && this.playerStatus == "playing") {
      runInAction(() => {
        this.playerStatus = "donepause";
        this.didJustFinish = status.didJustFinish;
      });
      console.log("update pause");
    }
  };

  pauseSong = async () => {
    if (this.soundPlayer != null) {
      await this.soundPlayer.pauseAsync();

      runInAction(() => {
        this.playerStatus = "donepause";
      });
    }
  };

  setPlayingPlaylist = async (list, index,album) => {
    runInAction(() => {
      this.playingIndexInPlaylist = index;
      this.playingPlaylist = list;
    
      this.playingAlbum = album
    });
  };

  pausePlay = async (link) => {
    if (this.radio) {
      await this.soundPlayer.pauseAsync();

      runInAction(() => {
        this.radio = false;
      });
    }
    runInAction(() => {
      this.radioMode = false;
    });

    console.log("CLick " + this.playerStatus);
    if (this.playerStatus == "nosound") {
      const sound = new Audio.Sound();
      sound.setOnPlaybackStatusUpdate(this.updateStatus);
      await Audio.setAudioModeAsync({
        playsInSilentModeIOS: true,
        staysActiveInBackground: true,
      });
      runInAction(() => {
        this.soundPlayer = sound;
      });
      this.playSong(link);
      Analytics.track(Analytics.events.PLAY_MUSIK,{title: this.playingPlaylist[this.playingIndexInPlaylist].title})
    } else if (this.playerStatus == "playing") {
      if (this.playingSong == link) {
        this.pauseSong();
      } else {
        console.log("die änderung");
        this.playSong(link);
        Analytics.track(Analytics.events.PLAY_MUSIK,{title: this.playingPlaylist[this.playingIndexInPlaylist].title})
   
      }
    } else if (this.playerStatus == "donepause") {
      if (this.playingSong == link) {
        if (this.didJustFinish) {
          await this.soundPlayer.replayAsync();
          Analytics.track(Analytics.events.PLAY_MUSIK,{title: this.playingPlaylist[this.playingIndexInPlaylist].title})
        } else {
          await this.soundPlayer.playAsync();
            }
      } else {
        this.playSong(link);
        Analytics.track(Analytics.events.PLAY_MUSIK,{title: this.playingPlaylist[this.playingIndexInPlaylist].title})
      }
    }
  };

  playSong = async (link) => {
    if (this.soundPlayer != null) {
      console.log(link);
      await this.soundPlayer.unloadAsync();
      await this.soundPlayer.loadAsync({ uri: link });
      await this.soundPlayer.playAsync();
      runInAction(() => {
        this.playingSong = link;
      });
    }
  };

  setRadio = async () => {
    if (this.playerStatus == "playing" && !this.radio) {
      this.pauseSong();
    }
    runInAction(() => {
      this.radioMode = true;
    });
    if (!this.radio) {
      runInAction(() => {
        this.radio = true;
        this.playingSong = this.radioLink;
      });
      Analytics.track(Analytics.events.PLAY_RADIO)

      try {
        this.soundPlayer = new Audio.Sound();
        await this.soundPlayer.loadAsync({ uri: this.radioLink });
        await Audio.setAudioModeAsync({
          playsInSilentModeIOS: true,
          staysActiveInBackground: true,
        });
        this.soundPlayer.setOnPlaybackStatusUpdate(this.updateStatus);
        await this.soundPlayer.playAsync();
      } catch (error) {
        runInAction(() => {
          this.error = true;
        });
      }
    } else {
      runInAction(() => {
        this.radio = false;
      });
      try {
        await this.soundPlayer.pauseAsync();
        await this.soundPlayer.unloadAsync();
      } catch (error) {}
    }
  };

  login = async () => {
    firebase
      .auth()
      .signInAnonymously()
      .then(() => {
        this.signedIn = true;
      })
      .catch((error) => {
        this.signedIn = false;
      });

      firebase.auth().onAuthStateChanged((user)=>{
        if(user){
          Analytics.setUserId(user.uid)
          console.log(user.uid)
        }
      })
  };

  getRadioLink = async () => {
    let doc = await firebase
      .firestore()
      .collection("config")
      .doc("radio")
      .get();
    runInAction(() => {
      this.radioLink = doc.data().url;
    });
  };

  getProgramm = async () => {
    runInAction(() => {
      this.programmRefresh = true;
    });
    var date = new Date().setHours(0, 0, 0, 0);
    await firebase
      .firestore()
      .collection("programm")
      .where("date", ">=", new Date(date))
      .get()
      .then((querySnapshot) => {
        const n = [];

        querySnapshot.forEach((doc) => {
          n.push({
            id: doc.id,
            title: doc.data().title,
            date: new Date(doc.data().date.toDate()).toLocaleDateString(
              "de-DE",
              {
                weekday: "short",
                year: "numeric",
                day: "2-digit",
                month: "2-digit",
              }
            ),
            time: new Date(doc.data().date.toDate()).toLocaleTimeString(
              "de-DE",
              { hour: "2-digit", minute: "2-digit" }
            ),
          });
        });

        const items = Object.entries(groupBy(n, "date")).map((e) => e[1]);

        runInAction(() => {
          this.programm = items;
          this.programmRefresh = false;
        });
      });
  };

  getNews = async () => {
    runInAction(() => {
      this.newsRefresh = true;
    });

    await firebase
      .firestore()
      .collection("news")
      .get()
      .then((querySnapshot) => {
        const n = [];
        querySnapshot.forEach((doc) => {
          n.push({
            id: doc.id,
            title: doc.data().title,
            image: doc.data().image,
            date: new Date(doc.data().date.toDate()).toLocaleDateString(),
            text: doc.data().text ?? "",
          });
        });

        runInAction(() => {
          this.news = n;
          this.newsRefresh = false;
        });
      });
  };

  getAlbums = async () => {
    runInAction(() => {
      this.albumsRefresh = true;
    });
    await firebase
      .firestore()
      .collection("album").orderBy('ord')
      .where("active", "==", true)
      .get()
      .then((querySnapshot) => {
        const n = [];
        querySnapshot.forEach((doc) => {
          n.push({
            id: doc.id,
            title: doc.data().title,
            image: doc.data().image,
            active: doc.data().active,
            info: doc.data().info,
            soon: doc.data().soon ?? false,
          });
        });

        runInAction(() => {
          this.albums = n;
          this.albumsRefresh = false;
        });
      });
  };

  getPlayList = async (item) => {
    await firebase
      .firestore()
      .collection("album")
      .doc(item.id)
      .collection("songs")
      .get()
      .then((querySnapshot) => {
        const n = [];
        querySnapshot.forEach((doc) => {
          n.push({
            id: doc.id,
            title: doc.data().title,
            link: doc.data().link,
            youtube: doc.data().youtube,
            author: doc.data().author,
            info: doc.data().info,
          });
        });

        runInAction(() => {
          this.playlist = n;
        });
      });
  };
}

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export default Store;
