import React from "react";
import { View, Image, Text, ScrollView } from "dripsy";
import { StyleSheet, TouchableOpacity, FlatList, Platform } from "react-native";

function Tabs(props) {
  const setScrollViewRef = React.useRef(null);
  const list = () => {
    return props.tabs?.map((element) => {
      return (
        <TouchableOpacity
          key={element.id}
          style={[
            element.id == props.active && {
              borderBottomWidth: 3,
              borderBottomColor: "#5d6e9a",
            },
            {
              marginRight: 20,
              height: 35,
            },
          ]}
          onPress={() => props.onClick(element.id)}
        >
          <Text
            sx={{
              fontSize: 25,
              color: element.id == props.active ? "second" : "mgrey",
              fontWeight: element.id == props.active ? "bold" : "normal",
            }}
          >
            {element.title}
          </Text>
        </TouchableOpacity>
      );
    });
  };

  return (
    <View sx={{height:60}}>
      <ScrollView
        pagingEnabled
        horizontal={true}
        contentContainerStyle={{ flexWrap: "wrap", margin: 20, height:35 }}
      >
        {list()}
      </ScrollView>
    </View>
  );
}

export default Tabs;
