import React from "react";
import Store from "./Store";


class RootStore {
    constructor(){
        this.store = new Store(this);
    }
}

const StoresContext = React.createContext(new RootStore());

export const useStores = () => React.useContext(StoresContext);